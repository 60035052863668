import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const Footer = () => {
  const { footer, wpMenu } = useStaticQuery(graphql`
    query footerQuery {
      wpMenu(name: { eq: "Footer Menu" }) {
        id
        menuItems {
          nodes {
            target
            label
            url
            menuItemFields {
              tooltip
            }
          }
        }
      }

      footer: wp {
        themeOptions {
          themeOptions {
            footerImageTooltip
            footerImageLink {
              title
              url
            }
            footerImage {
              altText
              localFile {
                publicURL
              }
            }
            businessAddress
            businessCity
            businessPostcode
            businessState
            businessStreetAddress
            businessEmail {
              target
              title
              url
            }
            businessPhone {
              target
              title
              url
            }
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <a
        data-tip
        data-for="footer__logo--tooltip"
        href={footer?.themeOptions?.themeOptions?.footerImageLink?.url}
        className="footer__logo"
        aria-label="Click this link to return to the home page"
      >
        <img
          src={
            footer?.themeOptions?.themeOptions?.footerImage?.localFile
              ?.publicURL
          }
          alt={footer?.themeOptions?.themeOptions?.footerImage?.altText}
        />
      </a>

      <div className="footer__contact">
        <Link
          to={footer?.themeOptions?.themeOptions?.businessAddress}
          target="_blank"
          rel="noreferrer"
        >
          <span>
            {footer?.themeOptions?.themeOptions?.businessStreetAddress}
          </span>
          <span>
            {footer?.themeOptions?.themeOptions?.businessCity}{" "}
            {footer?.themeOptions?.themeOptions?.businessState}{" "}
            {footer?.themeOptions?.themeOptions?.businessPostcode}
          </span>
        </Link>
        <a href={footer?.themeOptions?.themeOptions?.businessPhone?.url}>
          {footer?.themeOptions?.themeOptions?.businessPhone?.title}
        </a>
        <a
          data-tip="Email us"
          href={footer?.themeOptions?.themeOptions?.businessEmail?.url}
        >
          {footer?.themeOptions?.themeOptions?.businessEmail?.title}
        </a>
      </div>
      <ul className="footer__social">
        {wpMenu?.menuItems?.nodes?.map((menuItem, index) => {
          return (
            <li key={index}>
              <a
                target="_blank"
                rel="noreferrer"
                href={menuItem.url}
                data-tip={menuItem?.menuItemFields?.tooltip}
              >
                {menuItem.label}
              </a>
            </li>
          );
        })}
      </ul>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  padding: 3rem 1.5rem 4.5rem 1.5rem;
  background: var(--color-dark);
  position: relative;
  z-index: 1;

  @media (min-width: 48em) {
    padding: 3rem 3rem 4.5rem 3rem;
  }

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  a {
    transition: color 0.4s;
  }

  a:not([data-tip]):hover {
    color: var(--color-accent);
    transition: color 0.4s;
  }

  .footer__logo {
    width: 100%;
    margin-bottom: 1.5rem;

    @media (min-width: 48em) {
      margin-bottom: 0;
      width: calc(40% - 0.625rem);
    }

    img {
      height: 2rem;
      width: auto;
    }
  }

  * {
    font-size: 1.15rem;

    @media (min-width: 48em) {
      font-size: 1.25rem;
    }
  }

  .footer__contact {
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    @media (min-width: 48em) {
      margin-right: 4.5rem;
      margin-bottom: 0;
    }

    line-height: var(--lineHeight-tight);

    span,
    a {
      display: block;
    }
  }

  .footer__social {
    margin-top: 0;
    li {
      line-height: var(--lineHeight-tight);
      list-style-type: none;
      margin: 0;
      svg {
        height: 1.25rem;
      }
    }
  }
`;

export default Footer;
