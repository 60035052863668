import React from "react";
import styled, { css } from "styled-components";
import { Hero, ServicesGrid, Staff, Text } from "@components";
import { page_wrapper } from "@css/global";
import { ParallaxProvider } from "react-scroll-parallax";

const Block = ({ blockData }) => {
  /**
   * Trim off either Work_ or Page_ from the field group name.
   */
  const blockType = blockData.fieldGroupName.substring(5);

  const blocks = {
    Pageblocks_PageBlocks_StaffBlock: Staff,
    Pageblocks_PageBlocks_TextBlock: Text,
    Pageblocks_PageBlocks_Hero: Hero,
    Pageblocks_PageBlocks_ServicesGrid: ServicesGrid,
    page_default: <></>,
  };

  const BlockTag = blocks[blockType]
    ? blocks[blockType]
    : blocks["page_default"];

  return (
    <BlockWrapper
      id={blockData?.blockId ? blockData?.blockId : null}
      {...blockData}
    >
      <ParallaxProvider>
        <BlockTag {...blockData} />
      </ParallaxProvider>
    </BlockWrapper>
  );
};

const BlockWrapper = styled.section`
  max-width: 100%;
  width: 100%;

  ${props => {
    if (props.fieldGroupName !== "Page_Pageblocks_PageBlocks_Hero")
      return css`
        ${page_wrapper}
      `;
  }}

  ${props => {
    return css`
      padding-top: ${props.paddingTop !== null ? props.paddingTop : "0"}rem;
      padding-bottom: ${props.paddingBottom !== null
        ? props.paddingBottom
        : "0"}rem;
    `;
  }}
`;

export default Block;
