import React from "react";
import styled from "styled-components";
import { page_wrapper } from "@css/global";
import { Link } from "gatsby";

const Error = props => {
  return (
    <Wrapper>
      <h1>{props?.title}</h1>
      <Link to="/">Head back to our home page instead.</Link>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  ${page_wrapper};

  h1 {
    width: 100%;
    margin-bottom: 2rem;

    @media (max-width: 47.999em) {
      font-size: 2em;
    }
  }
`;

export default Error;
