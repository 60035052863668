import React from "react";
import styled from "styled-components";

const Text = ({ text }) => {
  return <Wrapper dangerouslySetInnerHTML={{ __html: text }} />;
};

const Wrapper = styled.article`
  a {
    padding-bottom: 0.125rem;
    border-bottom: 0.03125rem solid;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
`;

export default Text;
