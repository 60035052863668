import React, { useState } from "react";
import styled from "styled-components";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql, Link } from "gatsby";
import ReactPlayer from "react-player";
import { useParallax } from "react-scroll-parallax";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import { useWindowWidth } from "@react-hook/window-size";

const Hero = ({ heroLogo, heroMobile, scrollSpeed, slides, slidesMobile }) => {
  const { wp } = useStaticQuery(graphql`
    query Hero_Header_Query {
      wp {
        themeOptions {
          themeOptions {
            headerImage {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  SwiperCore.use([Autoplay, EffectFade]);

  const width = useWindowWidth({
    initialWidth: 768,
  });

  const { ref } = useParallax({
    speed: width < 768 ? 0 : 7.5,
  });

  const settings = {
    autoplay: {
      delay: scrollSpeed,
    },
    speed: 0,
    allowTouchMove: false,
    preloadImages: true,
    lazy: false,
    loadPrevNext: true,
    loadPrevNextAmount: 4,
  };

  const SlideWrapper = ({ slide, isActive }) => {
    const [videoReady, setVideoReady] = useState(false);

    return (
      <>
        {slide?.mimeType.includes("video") && (
          <>
            <ReactPlayer
              playing={isActive}
              loop={false}
              muted={true}
              width={"100%"}
              height={"100%"}
              className="hero__video"
              url={slide?.localFile?.publicURL}
              playsinline={true}
              config={{
                file: {
                  attributes: {
                    poster:
                      slide?.videoDuration?.videoThumbnail?.localFile
                        ?.publicURL,
                  },
                },
              }}
              onReady={() => {
                setVideoReady(true);
              }}
            />
            {slide?.videoDuration?.videoThumbnail && !videoReady && (
              <GatsbyImage
                className="hero__video--poster"
                image={getImage(
                  slide?.videoDuration?.videoThumbnail?.localFile
                )}
                alt={slide?.videoDuration?.videoThumbnail?.localFile.altText}
              />
            )}
          </>
        )}
        {slide?.mimeType.includes("gif") && (
          <img alt={slide?.altText} src={slide?.localFile.publicURL} />
        )}
        {!slide?.mimeType.match("video|gif") && (
          <GatsbyImage
            loading="eager"
            alt={slide?.altText || "An image of some work by Kindred."}
            image={slide?.localFile ? getImage(slide?.localFile) : null}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Wrapper {...{ heroMobile }}>
        <Swiper
          className="hero__slider hero__slider--desktop"
          ref={ref}
          {...settings}
        >
          {slides.map((slide, index) => {
            return (
              <SwiperSlide
                key={index}
                data-swiper-autoplay={
                  slide?.mimeType?.match(/video|gif/gi)
                    ? slide?.videoDuration?.videoDuration * 1000
                    : scrollSpeed
                }
              >
                {({ isActive }) => (
                  <SlideWrapper isActive={isActive} slide={slide} />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
        {heroMobile && (
          <Swiper className="hero__slider hero__slider--mobile" {...settings}>
            {slidesMobile.map((slide, index) => {
              return (
                <SwiperSlide
                  key={index}
                  data-swiper-autoplay={
                    slide?.mimeType?.match(/video|gif/gi)
                      ? slide?.videoDuration?.videoDuration * 1000
                      : scrollSpeed
                  }
                >
                  {({ isActive }) => (
                    <SlideWrapper isActive={isActive} slide={slide} />
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </Wrapper>

      {heroLogo && (
        <Link to="/" aria-label="Click this logo to go to the home page.">
          <HeroLogo
            className="hero__logo"
            src={
              wp?.themeOptions?.themeOptions?.headerImage?.localFile?.publicURL
            }
            alt={wp?.themeOptions?.themeOptions?.headerImage?.altText}
          />
        </Link>
      )}
    </>
  );
};

const HeroLogo = styled.img`
  position: relative;
  margin-bottom: calc((100vh - 3.5rem) * -1);
  margin-left: 1.5rem;
  @media (min-width: 48em) {
    margin-left: 3.5rem;
  }
  height: 12.5rem;
  width: auto;
  max-width: 50%;
  z-index: 3;
`;

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  max-width: 100%;

  * {
    height: 100%;
  }

  .hero__video--poster {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  .hero__slider--desktop {
    display: none;
    @media (min-width: 48em) {
      display: block;
    }
  }

  .hero__slider--mobile {
    display: block;
    @media (min-width: 48em) {
      display: none;
    }
  }

  .gatsby-image-wrapper,
  img,
  video {
    padding-bottom: 0 !important;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export default Hero;
