import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Footer } from "@components";
import loadable from "@loadable/component";
import ReactTooltip from "react-tooltip";
import { useStaticQuery, graphql } from "gatsby";
const AnimatedCursor = loadable(() => import("@components/cursor"));

const Layout = ({ children, hasHero }) => {
  const { footer } = useStaticQuery(graphql`
    query layoutQuery {
      footer: wp {
        themeOptions {
          themeOptions {
            footerImageTooltip
          }
        }
      }
    }
  `);

  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  return (
    <>
      <Main hasHero={hasHero}>
        {children}
        <Preloader id="preloader" className={pageLoaded ? "fadeout" : null} />
        <AnimatedCursor
          innerSize={27}
          outerSize={27}
          color="250, 96, 67"
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={1}
        />
        {/* Place a tooltip here to be called by any text components - required to ensure we can manage zindex from the same root */}
        <ReactTooltip
          className="text__tooltip"
          offset={{ top: -62, left: 0 }}
          arrowColor="transparent"
        />
        {footer?.themeOptions?.themeOptions?.footerImageTooltip && (
          <ReactTooltip
            id="footer__logo--tooltip"
            border={false}
            arrowColor="transparent"
            backgroundColor="var(--color-accent);"
            offset={{ top: -120, left: 0 }}
          >
            <p>{footer?.themeOptions?.themeOptions?.footerImageTooltip}</p>
          </ReactTooltip>
        )}
      </Main>
      <Footer />
    </>
  );
};

const Preloader = styled.div`
  position: fixed;
  background: var(--color-dark);
  height: 100%;
  width: 100%;
  z-index: 1001;

  &.fadeout {
    opacity: 0;
    z-index: -1;
    transition: opacity 0.4s, z-index 0s 0.4s;
  }
`;

const Main = styled.main`
  > * {
    animation: fadeIn 0.5s cubic-bezier(0.32, 0, 0.67, 0);

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  ${props => {
    if (props.hasHero) {
      return css`
        > article > section {
          &:nth-of-type(1) {
            > div {
              position: fixed;
              height: calc(100% + 5rem);
              /* Pulling -5rem above the viewport fixes errors with our parallax header where a margin would appear between it and the top of the body */
              top: -5rem;
            }
          }
          &:nth-of-type(2) {
            margin-top: 100vh;
          }
          &:not(:nth-of-type(1)) {
            background: var(--color-dark);
            position: relative;
            z-index: 2;
          }
        }
      `;
    }
  }}
`;

export default Layout;
