import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { isMobile } from "react-device-detect";
import ReactTooltip from "react-tooltip";
import ReactPlayer from "react-player";
import { sortBy } from "lodash";

const ServicesGrid = ({
  addServiceCategories,
  headingSize,
  lineHeight,
  serviceSize,
  gridColumn,
  gridRow,
}) => {
  return (
    <Wrapper {...{ headingSize, lineHeight, serviceSize, gridColumn, gridRow }}>
      {sortBy(addServiceCategories, node => {
        return node?.categoryOrder?.order;
      })?.map(({ name, services }, index) => {
        return (
          <div key={index}>
            <h2 className="services-grid__title">{name}</h2>
            <ul className="services-grid__content">
              {sortBy(services?.nodes, ({ menuOrder }) => menuOrder || 0)?.map(
                ({ title, featuredMedia }, serviceIndex) => {
                  return (
                    <React.Fragment key={serviceIndex}>
                      {!isMobile &&
                        (featuredMedia?.featuredImage !== null ||
                          featuredMedia?.featuredVideo !== null) && (
                          <>
                            <ReactTooltip
                              id={`services-grid__tooltip--${index}--${serviceIndex}`}
                              border={false}
                              arrowColor="transparent"
                              backgroundColor="transparent"
                              place="left"
                              offset={{ left: 0 }}
                              className={
                                featuredMedia?.featuredImage?.mediaDetails
                                  ?.height >
                                featuredMedia?.featuredImage?.mediaDetails
                                  ?.width
                                  ? "services-grid__toolip services-grid__tooltip--portrait"
                                  : "services-grid__toolip services-grid__tooltip--landscape"
                              }
                            >
                              {featuredMedia?.selectFeaturedMediaType ===
                                "image" && (
                                <GatsbyImage
                                  image={getImage(
                                    featuredMedia?.featuredImage?.localFile
                                  )}
                                  alt={
                                    featuredMedia?.featuredImage?.node?.altText
                                  }
                                />
                              )}
                              {featuredMedia?.selectFeaturedMediaType ===
                                "video" && (
                                <ReactPlayer
                                  playing={true}
                                  loop={true}
                                  muted={true}
                                  height={"100%"}
                                  width={"100%"}
                                  className="services-grid__video"
                                  url={
                                    featuredMedia?.featuredVideo?.localFile
                                      ?.publicURL
                                  }
                                  playsinline={true}
                                />
                              )}
                            </ReactTooltip>
                          </>
                        )}
                      <li
                        data-tip
                        data-for={`services-grid__tooltip--${index}--${serviceIndex}`}
                      >
                        {title}
                      </li>
                    </React.Fragment>
                  );
                }
              )}
            </ul>
          </div>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.article`
  display: grid;

  grid-gap: 1.5rem 2.5rem;
  grid-template-columns: minmax(0, max-content);

  @media (min-width: 48em) {
    grid-template-columns: repeat(2, minmax(0, max-content));
    grid-gap: ${props => props.gridRow}rem ${props => props.gridColumn}rem;
  }

  li,
  h2 {
    position: relative;
    z-index: 1000;
  }

  .services-grid__content {
    list-style-type: none;
    margin: 0;

    li {
      line-height: ${props => props?.lineHeight};
      font-size: ${props => props?.serviceSize}rem;
      margin: 0;
      transition: color 0.4s;

      @media (min-width: 48em) {
        &:hover {
          color: var(--color-accent);
          transition: color 0.4s;
        }
      }
    }
  }

  .services-grid__title {
    font-weight: bold;
    font-size: ${props => props?.headingSize}rem;
    margin-bottom: 0.25rem;
  }

  .services-grid__toolip {
    width: 580px !important;
    height: 425px !important;
    padding: 0;

    .gatsby-image-wrapper,
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &.services-grid__tooltip--portrait {
      width: 350px !important;
      height: 540px !important;
    }
  }
`;

export default ServicesGrid;
