export const image_aspect_landscape = `56.25%`;
export const image_aspect_portrait = `133%`;
export const image_aspect_landscape_deep = `66%`;

export const page_wrapper = `
    @media (min-width:0em){
        padding-left:1.5rem;
        padding-right:1.5rem;
    }
    @media (min-width:48em){
        padding-left:40%;
        padding-right:3rem;
    }
    @media (min-width:75em){
        padding-right:6rem;
    }
`;
