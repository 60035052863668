import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Staff = ({ staffMembers }) => {
  return (
    <StaffMembers>
      {staffMembers?.map((staffMember, index) => {
        return (
          <StaffMember className="staff__staff-member" key={index}>
            <GatsbyImage
              image={getImage(staffMember?.staffImage?.localFile)}
              alt={staffMember?.staffName}
            />
            <figcaption>
              <h2>{staffMember?.staffName}</h2>
              <h3>{staffMember?.staffRole}</h3>
            </figcaption>
          </StaffMember>
        );
      })}
    </StaffMembers>
  );
};

const StaffMembers = styled.article`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: minmax(0, 1fr);
  padding-right: 3rem;

  @media (min-width: 64em) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-right: 7.5rem;
  }
`;

const StaffMember = styled.figure`
  position: relative;
  margin: 0;
  height: auto;

  .gatsby-image-wrapper {
    padding-bottom: 100%;
    height: 0;
    width: 100%;
    inset: 0;
  }

  figcaption {
    position: static;
    padding: 1.5rem 0 0.75rem 0;

    @media (hover: hover) {
      padding: 1.5rem;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      background: rgba(0, 0, 0, 0.75);
      transition: opacity 0.4s;

      &:hover {
        opacity: 1;
        transition: opacity 0.4s;
      }
    }

    h2,
    h3 {
      width: 100%;
      margin: 0;
      font-size: 1.4rem;
      @media (min-width: 64em) {
        font-size: 1.875em;
      }

      color: white;
      font-weight: 400;
    }
  }
`;

export default Staff;
